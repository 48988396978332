import 'react-accordion-ts/src/panel.css';
import './faqs.css';

import * as React from 'react';
import styled from "styled-components/macro";

import {MEDIA_WIDTHS, Z_INDEX} from "../../theme";

// import { Accordion } from 'react-accordion-ts';
// import Card from "../../components/Card";
// import AppBody from "../AppBody";

const news = [
    {
        title: 'WHAT IS STAKING?',
        faqContent: "When you stake your $XNX Finance, you auto-magically receive $POS that will be used as proof-of-stake. With the $POS received you are able to stake for rewards in the STAKING Pool. Can I become who I want to be?",
        open: true,
    },
    {
        title: 'WHAT HAPPENS IF I SELL MY POS TOKEN?',
        faqContent: "Please do not sell/exchange your $POS. If you sell your $FLIP you won't be able to unstake your $XNX as you need the same amount of $POS to do so.",
        open: true
    },
    {
        title: 'HOW CAN I STAKE $XNX?',
        faqContent: "You can stake your XNX on the 'Staking' page by clicking on the little '+' sign after approving the contract, choose the amount you want to stake and confirm.",
        open: false
    },
    {
        title: 'WHAT IS THE DIFFERENCE BETWEEN STAKING AND FARMING?',
        faqContent: "STAKING: In order to stake your $XNX you don't need anything else than... well... $XNX. FARMING: In order to farm you need to provide liquidity to one of the pools on the NFT farming page and then stake your NFT LP token. Both have their own advantages and disadvantages. Which one to choose is up to your strategy.",
        open: false
    },
    {
        title: 'HOW DO I FARM?',
        faqContent: "In order to farm you first have to provide liquidity to one of the pools which you can find on the 'NFT Farm' page. After providing liquidity and receiving your NFT LP token then select the pool you joined from the 'NFT Farm' page, approve the contract and then click on the little '+' sign, choose the NFT you want to stake and confirm.",
        open: false
    },
    {
        title: 'HOW DO I CONNECT MY WALLET TO XNX FINANCE AMM?',
        faqContent: "In order to connect your wallet you have to click on the 'Connect Wallet' button on the top-right corner of the page. After that, look for the wallet you want to connect to XNX Finance and click 'connect'. Approve the connection and you're good to go.",
        open: false
    },
    {
        title: 'HOW DO I SET UP MY WALLET ON POLYGON CHAIN?',
        faqContent: "Trust Wallet: When you go into the dAPP in the top-right corner you can see the different blockchains available, click on it then choose Polygon Chain Mainnet. MetaMask: Create a custom RPC with the following: Polygon Mainnet in the Network Name field, URL https://polygon-rpc.com/ in the New RPC URL field, 137 in Chain ID field, MATIC in Currency Symbol field and https://polygonscan.com/ in Block Explorer URL field.",
        open: false
    },
    {
        title: 'WHEN WILL YOU OPEN MORE POOLS?',
        faqContent: "There will always be an announcement before the launch of new pools. Join the announcements Telegram group to be the first that finds out.",
        open: false
    },
    {
        title: 'HOW DO I GET AIRDROPS?',
        faqContent: "When there is any official airdrop going on it will be announced along with the requirements. Please remember anyone can airdrop tokens to users since every transaction is public on PolygonScan. Do your own due diligence when it comes to non-official airdrops.",
        open: false
    },
    {
        title: 'WHY DOES IT SAY I HAVE NO MATIC BALANCE?',
        faqContent: "You're most likely not connected to Polygon Chain within your wallet.",
        open: false
    },
    {
        title: 'WHAT IS THE MAX SUPPLY OF XNX?',
        faqContent: "$XNX does not have a maximum supply. The circulating supply is managed through built-in burning and regular burning events.",
        open: false
    },

];

const items = news.map(({ open, title, faqContent}) => ({
    open,
    title: <h2>{title}</h2>,
    content: (
        <>
            <p>{faqContent}</p>
            {/* {content.map((item: string, index: number) => (
				<p key={index}>{item}</p>
			))} */}
        </>
    )
}));

// export default function Faqs(){
//     return (
//         <Accordion items={items} duration={300} multiple={false} open={0} />
//     )
// }

const PageTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
`

const OverBody = styled.div`
    position: relative;
  margin-top: 0px;
  max-width: 480px;
  width: 100%;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 24px;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  z-index: ${Z_INDEX.deprecated_content};
`

const Header = styled.span`
  color: black;
  font-size: 18px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold
`

const Image = styled.div`
    width: 360px;
    @media screen and (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
        width: 300px;
    }
`

export default function Faqs(){
    return (
        <OverBody>
            <PageTitle>
                <Header>NFT FARMING<br />( Launching Soon! )</Header>
            </PageTitle>
            <div style={{justifyContent: "center", display: "flex"}}>
                <Image>
                    <img src="/images/nft_farming.jpeg" alt="xnx logo" style={{width: "inherit"}} />
                </Image>
            </div>
        </OverBody>
    )
}
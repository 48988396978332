import { FACTORY_ADDRESS as V2_FACTORY_ADDRESS } from '@uniswap/v2-sdk'
import { FACTORY_ADDRESS as V3_FACTORY_ADDRESS } from '@uniswap/v3-sdk'

import { constructSameAddressMap } from '../utils/constructSameAddressMap'
import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }

export const UNI_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984'),
  [SupportedChainId.BINANCE_TEST_CHAIN]: '0x9cFD03403348Aa1a09124009f30457bE68ACD659',
  [SupportedChainId.BINANCE_SMART_CHAIN]: '0x9E24415d1e549EBc626a13a482Bb117a2B43e9CF',
  [SupportedChainId.POLYGON] : '0xca70CF8449522596A8eBD6c6BFb5348F5071815e'
}
export const MULTICALL_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x1F98415757620B543A52E61c46B32eB19261F984', [
    SupportedChainId.OPTIMISTIC_KOVAN,
    SupportedChainId.OPTIMISM
  ]),
  [SupportedChainId.POLYGON]: '0x38cBF5C03569A0Cabc0eA0B43eD914e4EF45CE1d',
  [SupportedChainId.ARBITRUM_ONE]: '0xadF885960B47eA2CD9B55E6DAc6B42b7Cb2806dB',
  [SupportedChainId.ARBITRUM_RINKEBY]: '0xa501c031958F579dB7676fF1CE78AD305794d579',
  [SupportedChainId.BINANCE_TEST_CHAIN]: '0x38DCa32821C7305bcDd1943Eb8593796D8b96dee',
  [SupportedChainId.BINANCE_SMART_CHAIN]: '0xaA411E67d55c6bDa52a5eACdAc9966a0198739E4'
}
export const V2_FACTORY_ADDRESSES: AddressMap = {
  ...constructSameAddressMap(V2_FACTORY_ADDRESS),
  [SupportedChainId.POLYGON]: '0x5757371414417b8C6CAad45bAeF941aBc7d3Ab32',
  [SupportedChainId.BINANCE_TEST_CHAIN]: '0x141f66837A9c243d1cd529df4e695f47fe4a3881',
  [SupportedChainId.BINANCE_SMART_CHAIN]: '0xca143ce32fe78f1f7019d7d551a6402fc5350c73'
}

export const V2_ROUTER_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D'),
  [SupportedChainId.POLYGON]: '0xa5e0829caced8ffdd4de3c43696c57f7d7a678ff',
  [SupportedChainId.BINANCE_TEST_CHAIN]: '0x02aA57Dd23b0d5f3f1B9FB4a329E923F1Ba12821',
  [SupportedChainId.BINANCE_SMART_CHAIN]: '0x10ED43C718714eb63d5aA57B78B54704E256024E'
}

export const V3_ROUTER_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0xE592427A0AEce92De3Edee1F18E0157C05861564', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISTIC_KOVAN,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY
  ]),
  [SupportedChainId.POLYGON]: '0x4cF6bE21D50fFf516d84Ef6127889938872264a4',
  [SupportedChainId.BINANCE_TEST_CHAIN]: '0x5263d55e8413FF113D6987c88Ee1310e30d6697d',
  [SupportedChainId.BINANCE_SMART_CHAIN]: '0x58fF14aFaD4Bb316eF19D3f55b4FC85156b88FcB'
}
export const SWAP_ROUTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISTIC_KOVAN,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY
  ]),
  [SupportedChainId.POLYGON]: '0xdC0c8f12B512985b9F7d29ee6304bc9bB228BbF8',
  [SupportedChainId.BINANCE_TEST_CHAIN]: '0xCa7cEb383C2b2843F8959aA86F1f0e0e7b6E1C59',
  [SupportedChainId.BINANCE_SMART_CHAIN]: '0xE079bcD2B18A98354FB469a0A5579592473dDAc9'
}

/**
 * The oldest V0 governance address
 */
export const GOVERNANCE_ALPHA_V0_ADDRESSES: AddressMap = constructSameAddressMap(
    '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'
)
/**
 * The older V1 governance address
 */
export const GOVERNANCE_ALPHA_V1_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0xC4e172459f1E7939D522503B81AFAaC1014CE6F6',
}
/**
 * The latest governor bravo that is currently admin of timelock
 */
export const GOVERNANCE_BRAVO_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x408ED6354d4973f66138C91495F2f2FCbd8724C3',
}

export const TIMELOCK_ADDRESS: AddressMap = constructSameAddressMap('0x1a9C8182C09F50C8318d769245beA52c32BE35BC')

export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e',
}
export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8',
}
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...constructSameAddressMap(V3_FACTORY_ADDRESS, [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISTIC_KOVAN,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY
  ]),
  [SupportedChainId.POLYGON]: '0x97de4A0d665852427E7e4d2b71eeD456872E4959',
  [SupportedChainId.BINANCE_TEST_CHAIN]: '0x93bD41749931D03ea94497a556c8df24465C2902',
  [SupportedChainId.BINANCE_SMART_CHAIN]: '0xBd45e5c40bC4b15183cf15A74325227AF1641887'
}
export const QUOTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISTIC_KOVAN,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY
  ]),
  [SupportedChainId.POLYGON]: '0x955fbb72A00A1d1F79584Ea1D4911CE054992fA8',
  [SupportedChainId.BINANCE_TEST_CHAIN]: '0x903024Fc1ADF990d20c2003a487Aaa48Ef9DD9D4',
  [SupportedChainId.BINANCE_SMART_CHAIN]: '0xC22Dc88F9f3FCaf0AcADE3B98EEe62591150575e'
}
export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
      [
        SupportedChainId.OPTIMISM,
        SupportedChainId.OPTIMISTIC_KOVAN,
        SupportedChainId.ARBITRUM_ONE,
        SupportedChainId.ARBITRUM_RINKEBY
      ]
  ),
  [SupportedChainId.POLYGON]: '0x921d021556F7bf8C8d1Daa5F289D6a0Bd0c8322f',
  [SupportedChainId.BINANCE_TEST_CHAIN]: '0xB842Ee61B56C25df47F12Fc19E9441E1f5b284a1',
  [SupportedChainId.BINANCE_SMART_CHAIN]: '0xd64023c388541dca887a9193AfA2c23d03D1B0FF'
}
export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.ROPSTEN]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.GOERLI]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.RINKEBY]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
}
export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x65770b5283117639760beA3F867b69b3697a91dd',
}

export const V3_MIGRATOR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xA5644E29708357803b5A882D272c41cC0dF92B34', [
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY
  ]),
  [SupportedChainId.POLYGON]: '0x54b6733E8f7B4c32743948aa786bfCfDb49A153A',
  [SupportedChainId.BINANCE_TEST_CHAIN]: '0x0fc2b3e10e0249712DE3B98d08Ff5453A0adFD11',
  [SupportedChainId.BINANCE_SMART_CHAIN]: '0xF5f8C4CA786a35B8c1fDE9E5FB2c6860254E0B3A'
}

export const TICK_LENS_ADDRESSES: AddressMap = {
  [SupportedChainId.ARBITRUM_ONE]: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573',
  [SupportedChainId.ARBITRUM_RINKEBY]: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573',
  [SupportedChainId.POLYGON]: '0x03B2Ab8AAF3980E15b1eb507a54fCF95fc2DEB7d',
  [SupportedChainId.BINANCE_TEST_CHAIN]: '0xB2Db4b39511B15B19099c6BE10e57b923957fa93',
  [SupportedChainId.BINANCE_SMART_CHAIN]: '0x3746b2174B7Cc5CC2Fb031b096F4723AcF5d0c52'
}

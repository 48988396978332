import {useEffect} from "react";

import {BANNER_URL} from "../../constants/misc";
import {useAppDispatch} from "../hooks";
import {setBanners} from "./index";


export const useBanners = () => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        (async () => {
            const jsonRes = await fetch(BANNER_URL)
            const jsonData = await jsonRes.json()
            dispatch(setBanners(jsonData))
        })()
    }, [dispatch])
}